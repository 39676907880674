import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { BaseFilters, ExtendedColumn } from 'store/services/utilsTypes'

import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import ProductView from './panel/ProductView'
import HeaderCard from 'libs/card/HeaderCard'
import MotionDiv from 'libs/motionDiv'
import SidePanel from 'libs/sidepanel/SidePanel'
import useTabTitle from 'libs/hooks/useTabTitle'
import Popup from 'libs/popup/Popup'
import Tooltip from 'libs/tool-tip/Tooltip'
import WhiteCard from 'libs/card/WhiteCard'

import DeleteWarehouse from './popup/WareHouseDelete'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import { formatNumber } from 'utils/numberHandlers'

import StorageView from './panel/StorageView'
import FilterPanel from './panel/FilterPanel'

import {
  Warehouse,
  WarehouseAdditionalFilters,
} from './warehouses-endpoints/warehouseTypes'
import { CellProps } from 'react-table'

import {
  useGetWarehouseAndStorageCountQuery,
  useGetWarehousesQuery,
} from './warehouses-endpoints/warehouses.endpoints'
import FilterableSearchTable from 'libs/table/filterable-search-table_new/FilterableSearchTable'

const additionalFilters: WarehouseAdditionalFilters = {
  created_between: [],
  updated_between: [],
  allowed_products: [],
}

const WarehousePage = () => {
  useTabTitle('Warehouses')
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)

  const [isShowFilter, setIsShowFilter] = useState(false)

  const [filters, setFilters] = useState<
    BaseFilters & WarehouseAdditionalFilters
  >({
    page_no: 1,
    page_size: 10,
    search_text: '',
    sort_by: 'created_on',
    sort_dir: 'DESC',
    created_between: [],
    updated_between: [],
    allowed_products: [],
  })

  const { data: warehouseAndStorageCount } =
    useGetWarehouseAndStorageCountQuery(
      {
        plant_id: plant?.id,
      },
      {
        skip: !plant?.id,
      },
    )

  const [deleteWarehousePopUp, setDeleteWarehousePopUp] = useState<{
    isPopUpOpen: boolean
    warehouse: Warehouse | null
  }>({
    isPopUpOpen: false,
    warehouse: null,
  })
  const [viewStorageAreaSidePanel, setViewStorageAreaSidePanel] = useState<{
    isPopUpOpen: boolean
    warehouse: Warehouse | null
  }>({
    isPopUpOpen: false,
    warehouse: null,
  })
  const [viewProductSidePanel, setViewProductSidePanel] = useState<{
    isPopUpOpen: boolean
    warehouse: Warehouse | null
  }>({
    isPopUpOpen: false,
    warehouse: null,
  })

  const columns: ExtendedColumn<Warehouse>[] = useMemo(
    () => [
      {
        Header: 'Warehouse Names',
        accessor: 'name',
        Cell: ({ row }: CellProps<Warehouse>) => {
          return (
            <div
              className="hyperlink flex gap-3 underline"
              onClick={() => {
                navigate(`/settings/configure/warehouses/${row.original.id}`)
              }}
            >
              {row.original?.name ?? '-'}
            </div>
          )
        },
        width: 250,
      },
      {
        Header: 'Storage areas',
        accessor: 'storage_areas',
        width: 200,
        Cell: ({ row }: CellProps<Warehouse>) => {
          const value = row?.original?.storage_areas
          const displayStorageArea = value
            ?.slice(0, 2)
            .map((item) => item?.name)

          const remainingCount = value?.length - displayStorageArea?.length
          return displayStorageArea?.length > 0 ? (
            <div className="flex flex-wrap">
              {displayStorageArea?.map((name, index) => (
                <span
                  key={index}
                  className={`m-1 rounded-[28px] bg-warning-100 px-2 py-0.5 text-warning-900`}
                >
                  {name}
                </span>
              ))}
              <div>
                {remainingCount > 0 && (
                  <button
                    onClick={() => {
                      row.original &&
                        setViewStorageAreaSidePanel({
                          isPopUpOpen: true,
                          warehouse: row.original,
                        })
                    }}
                    className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold"
                  >
                    +{remainingCount} more
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>-</div>
          )
        },
      },
      {
        Header: 'Products',
        accessor: 'updated_by',
        Cell: ({ row }: CellProps<Warehouse>) => {
          const allProducts = row.original.storage_areas
            ?.flatMap((storageArea) => storageArea.products) // Flatten all products in storage areas
            ?.map((product) => `${product.name} (${product.quantity})`) // Extract the name of each product

          // Display only the first two product names, if there are more, show the remaining count
          const displayProduct = allProducts?.slice(0, 2)
          const remainingCount = allProducts.length - displayProduct.length
          return displayProduct?.length > 0 ? (
            <div className="flex flex-wrap">
              {displayProduct?.map((name, index) => (
                <span
                  key={index}
                  className={`m-1 rounded-[28px] bg-warning-100 px-2 py-0.5 text-warning-900`}
                >
                  {name}
                </span>
              ))}
              <div>
                {remainingCount > 0 && (
                  <button
                    onClick={() => {
                      row.original &&
                        setViewProductSidePanel({
                          isPopUpOpen: true,
                          warehouse: row.original,
                        })
                    }}
                    className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold"
                    data-testid="storage-count"
                  >
                    +{remainingCount} more
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>-</div>
          )
        },
      },
      {
        Header: 'Last updated',
        accessor: 'updated_on',
        Cell: ({ row }: CellProps<Warehouse>) => {
          const formattedDate = getRelativeTime(row.original?.updated_on)
          return formattedDate + ' by ' + row.original?.updated_by
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        width: 20,
        Cell: ({ row }: CellProps<Warehouse>) => (
          <div className="flex w-full items-center justify-between  ">
            <div className="flex flex-row gap-2">
              <Tooltip content="Edit Details">
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(
                      `/settings/configure/warehouses/edit/${row.original?.id}`,
                    )
                  }}
                >
                  <Pen />
                </button>
              </Tooltip>
              <Tooltip content="Delete Details">
                <button
                  className={`mr-6`}
                  onClick={(event) => {
                    event.stopPropagation()
                    row.original &&
                      setDeleteWarehousePopUp({
                        isPopUpOpen: true,
                        warehouse: row.original,
                      })
                  }}
                >
                  <Bin />
                </button>
              </Tooltip>
            </div>
            <Tooltip content="View Details" position="left">
              <span
                className="cursor-pointer"
                onClick={() =>
                  row?.original?.id &&
                  navigate(`/settings/configure/warehouses/${row.original.id}`)
                }
              >
                <ClickableRowArrow />
              </span>
            </Tooltip>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const handleAddWarehouse = () => {
    navigate('/settings/configure/warehouse/new')
  }

  return (
    <MotionDiv>
      <>
        <div className="flex flex-col gap-4">
          <div>
            <h1 className="h4-bold-black ">Warehouses</h1>
            <div className="flex justify-between">
              <p className="w-4/5 p-sm-regular">
                View the details of your added warehouses and easily add new
                ones as needed
              </p>
            </div>
          </div>

          <>
            <WhiteCard>
              <div className="flex gap-4">
                <HeaderCard
                  value={
                    warehouseAndStorageCount?.warehouse_count > 0
                      ? formatNumber(warehouseAndStorageCount?.warehouse_count)
                      : '-'
                  }
                  title={t('warehouse.total_warehouses')}
                />
                <HeaderCard
                  value={
                    warehouseAndStorageCount?.storage_area_count > 0
                      ? formatNumber(
                          warehouseAndStorageCount?.storage_area_count,
                        )
                      : '-'
                  }
                  title={t('warehouse.total_storage_areas')}
                />
              </div>
            </WhiteCard>
            <FilterableSearchTable
              useQuery={useGetWarehousesQuery}
              columns={columns}
              addButton={{
                label: 'Add Warehouse',
                onClick: handleAddWarehouse,
              }}
              placeholders={{
                search: 'Search by warehouse name',
                emptyMessage:
                  'No warehouse added to show. Add a warehouse in 2 easy steps.',
                filterEmptyMessage: 'No matching results found',
              }}
              filterPanelComponent={FilterPanel}
              additionalFilters={additionalFilters}
            />
            {deleteWarehousePopUp.warehouse && (
              <Popup
                isOpen={deleteWarehousePopUp?.isPopUpOpen}
                title="Delete warehouse"
                onClose={() => {
                  setDeleteWarehousePopUp({
                    isPopUpOpen: false,
                    warehouse: null,
                  })
                }}
              >
                <DeleteWarehouse
                  closePopUp={() => {
                    setDeleteWarehousePopUp({
                      isPopUpOpen: false,
                      warehouse: null,
                    })
                  }}
                  warehouse={deleteWarehousePopUp.warehouse}
                />
              </Popup>
            )}

            <SidePanel
              isOpen={isShowFilter}
              title="Filters"
              size="large"
              subTitle="Filter the data according to your requirements"
              onClose={() => {
                setIsShowFilter(false)
              }}
            >
              <FilterPanel
                filters={filters}
                closePanel={() => {
                  setIsShowFilter(false)
                }}
                setFilters={setFilters}
              />
            </SidePanel>
            {viewStorageAreaSidePanel.warehouse && (
              <SidePanel
                isOpen={viewStorageAreaSidePanel?.isPopUpOpen}
                title="Storage Areas Details"
                size="large"
                onClose={() => {
                  setViewStorageAreaSidePanel({
                    isPopUpOpen: false,
                    warehouse: null,
                  })
                }}
              >
                <StorageView warehouse={viewStorageAreaSidePanel.warehouse} />
              </SidePanel>
            )}

            {viewProductSidePanel.warehouse && (
              <SidePanel
                isOpen={viewProductSidePanel?.isPopUpOpen}
                title="Storage Areas Details"
                size="large"
                onClose={() => {
                  setViewProductSidePanel({
                    isPopUpOpen: false,
                    warehouse: null,
                  })
                }}
              >
                <ProductView warehouse={viewProductSidePanel.warehouse} />
              </SidePanel>
            )}
          </>
        </div>
      </>
    </MotionDiv>
  )
}

export default WarehousePage
